.server {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    border-radius: 5px;

}

.server:hover {
    background-color: rgb(232, 240, 252);
}

.server_lnk {
    padding: 4px;
}

.HeaderServer .hide {
    display: none;
}


.Disconnected {
    color: #AA4444 !important;
}

.Connecting {
    color: #666666 !important;
}

.Connected {
    color: #448844 !important;
}


#toast-container > .toast {
    max-width: 260px;
}
.modal900 {
    width: 900px !important;
}



.modal-dialog {
    max-width:900px !important;
}


.UnidadeCadastro select {
    height: 36px !important;
}

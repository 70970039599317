.Header {
    margin-bottom: 0;
    z-index:100;
}


.Header .navbar-minimalize {
    margin-right: 2px
}

.Header li {
    padding: 0 0px 0 0px !important;
}


.Header .welcome-message {
    font-size: 13px !important;
}

.hide {
    display: none !important;
}

.show {
    display:block !important 
}


.Header .dropdown-menu {
    width:350px !important;
    align-items:flex-start;
    align-content: flex-start;
    left: 15px;
}






.Login {
    background: #ededed;
    width: 100vw !important;
    /*height: 135vh !important;*/
    position: relative !important;
}


.Login .painel {
    background-color: rgba(255, 255, 255, 0.84);
    padding: 0;
    border-radius: 10px;
    box-shadow: 0 10px 1em rgba(0, 0, 0, 0.5);
    height: 500px;
    width: 300px;
    overflow: hidden;
}

.Login #login .painel {
    padding: 0 15px 15px 15px;
}



.Login .logo {
    padding: 20px 15px 15px 15px;
}


.Login .vertical-center {
    /*margin: 0  !important;
    position: absolute !important;
    top: 50% !important;
    left:  50% !important;
    -ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    width: 300px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -300px;*/
}


.Login .field-icon {
    float: right;
    margin-right: 8px;
    margin-top: -27px;
    position: relative;
    cursor: pointer;
    font-size:17px;
    z-index: 2;
}


.Login .login-pad {
    margin-top: 50px !important;
    /*padding-left: 25px;
    border: 1px red solid;*/
}

.Login .painels {
    margin: auto;
    width: 980px;
}


.painel-expand {
    width: 630px !important;
    height: 750px !important;
    transition: all .2s linear;
    overflow-y: scroll !important;
}


.painel .ibox {
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.feed-element {
   text-align: left;
}



.painel::-webkit-scrollbar {
    width: 14px;
}
  
.painel::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}
  
.painel::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #8070d4;
}


.presents_ex, .login_ex, .cadastro_ex {
    color: #ffffff;
    cursor: pointer;
}

.btn-pad {
    margin: 3px;
}

.cad_pre_info {
    padding: 15px;
    text-align: left;
}

.Login .planos {
    margin: auto;
    width: 980px;
    padding: 50px 0 0 0 ;
}

.Login .ibox  {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 1em rgba(0, 0, 0, 0.3);
}


.Login .login-footer {
    color: #8070d4;
}


::-webkit-scrollbar {
    width: 14px;
}
  
::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}
  
::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #8070d4;
}





@media (max-width: 1005px) {
    .Login {
        background: #ededed;
        width: 100vw !important;
        height: 135vh !important;
        position: relative !important;
    }

    #painels {
        width: 980px;
    }


    .painel, .col-lg-4  {  
        width:320px;
    }


    #presents {
        display:block;
       
    }


    #cadastro {
        display:block;
        
    }


    #login {
        display:block;
       
    }


    .painel-expand {
        display:block;
        width: 630px !important;
        height: 750px !important;
        transition: all .2s linear;
        overflow-y: scroll !important;
    }
  }
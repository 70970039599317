.CameraConfig {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    overflow-x: hidden;
    direction: ltr;
    scrollbar-color: #d4aa70 #e4e4e4;
    scrollbar-width: thin;
}

.CameraConfig::-webkit-scrollbar {
    width: 14px;
}
  
.CameraConfig::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}
  
.CameraConfig::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #8070d4;
}

.CameraConfig #edit_mode {
    width: 22px;
    height: 22px;
}
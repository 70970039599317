.hide {
    display: none;
}

.clear {
    clear: both;
}


.dashnav {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 62px;
    bottom: 30px;
    left: 0;
    right: 0;
}




#map {
    height: 100%;
    width: 100%;
    top: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}



.grid .ibox {
    margin-bottom: 0;
    padding: 10px;
    background-color: #FBFBFB !important;
}

.grid-item {
    margin-bottom: 25px;
    width: 435px;
    padding: 0 10px 0 10px;
}

.grid-box {
}


.fullscreen-pac-container[style] {
    z-index: 2547483647 !important;
    top: 50px !important;
}


.text-primary {
    color: #048EA2 !important;
}

.open_side {
    position: absolute;
    top: 128px;
    right: 36px;
    z-index: 9999 !important;
}

.sidebar-container {
    background-color: rgba(255,255,255,.85);
    border-left: 1px solid #8070d4;
    border-top: 1px solid #e7eaec;
    overflow: hidden;
    position: fixed;
    top: 60px;
    width: 350px !important;
    height: calc(100% - 40px) !important;
    z-index: 999 !important;
    bottom: 200px !important;
    right: 0;  
}


.sidebar_action_close {
    right: -350px;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
}

.sidebar_action_open {
    right: 0px;
    -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 1s; /* Firefox < 16 */
    -ms-animation: fadeout 1s; /* Internet Explorer */
    -o-animation: fadeout 1s; /* Opera < 12.1 */
    animation: fadeout 1s;
}


@keyframes fadein {
    from {
        right: 0;
    }

    to {
        right: -350px;
    }
}

@-moz-keyframes fadein {
    from {
        right: 0;
    }

    to {
        right: -350px;
    }
}

@-webkit-keyframes fadein {
    from {
        right: 0;
    }

    to {
        right: -350px;
    }
}

@-ms-keyframes fadein {
    from {
        right: 0;
    }

    to {
        right: -350px;
    }
}


@keyframes fadeout {
    from {
        right: -350px;
    }

    to {
        right: 0;
    }
}

@-moz-keyframes fadeout {
    from {
        right: -350px;
    }

    to {
        right: 0;
    }
}

@-webkit-keyframes fadeout {
    from {
        right: -350px;
    }

    to {
        right: 0;
    }
}

@-ms-keyframes fadeout {
    from {
        right: -350px;
    }

    to {
        right: 0;
    }
}


.jstree-contextmenu {
    z-index:9000;
}


#cadastro .modal-body {
    height: 550px;
    overflow-y: auto;
}



.glow {
    -webkit-box-shadow: 0px 0px 3px 5px #b61313;
    -moz-box-shadow: 0px 0px 3px 5px #b61313;
    box-shadow: 0px 0px 3px 5px #b61313; 
    border:  #b61313 1px solid;
  }
  
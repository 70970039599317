.UnidadesLista .table tr {
    cursor: pointer;
}


.UnidadesLista #uf_codigo {
    width: 180px;
    height: 35px;
}

.UnidadesLista #btnBusca {
    height: 35px;
}
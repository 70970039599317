.ServersTab {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    overflow-x: hidden;
    direction: ltr;
    scrollbar-color: #d4aa70 #e4e4e4;
    scrollbar-width: thin;
}

.ServersTab::-webkit-scrollbar {
    width: 14px;
}
  
.ServersTab::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}
  
.ServersTab::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #8070d4;
}

.ServersTab #edit_mode {
    width: 22px;
    height: 22px;
}

.ServersTab .server 
{
    width: 98%;
    height: 50px;
    background-color: #483c851f;
    border-radius: 5px;
    margin-bottom:5px;
    display: grid;
    grid-template-columns: 55px auto;
    padding:5px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    
}

.ServersTab .server:hover
{
    background-color: #FFFFFF;
    box-shadow: 1px 1px 1px 1px inset #888888;
}



.ServersTab .server_name {
    color:#132b50;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


select {
    height: 36px !important;
}

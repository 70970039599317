.AtividadesTab {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    overflow-x: hidden;
    direction: ltr;
    scrollbar-color: #d4aa70 #e4e4e4;
    scrollbar-width: thin;
}

.AtividadesTab::-webkit-scrollbar {
    width: 14px;
}
  
.AtividadesTab::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}
  
.AtividadesTab::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #8070d4;
}

.AtividadesTab #edit_mode {
    width: 22px;
    height: 22px;
}

.AtividadesTab .atividade img {
    width:26px !important;
    height:26px !important;
}

.AtividadesTab .atividade
{
    width: 98%;
    background-color: #5c509b1f;
    border-radius: 5px;
    margin-bottom:5px;
    display: grid;
    grid-template-columns: 36px auto;
    padding:5px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}


.AtividadesTab .atividade:hover
{
    background-color: #FFFFFF;
    box-shadow: 1px 1px 1px 1px inset #CCCCCC;
}



.AtividadesTab .atividade_name {
    color:#af0101;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AtividadesTab .atividade {
    color:#000000 !important;
}


.AtividadesTab .small {
   float: right;
   margin-top: 2px;
   color:#ffffff;
   background-color: #af01018e;
}




#Reativar {
    background-color: #FFF;
}

#Reativar .div_paymentforms img {
    padding:5px;
}


#Reativar {
    text-align: left !important;
}

.hide {
    display: none;
}
.AtividadesLista .table tr {
    cursor: pointer;
}


.AtividadesLista #uf_codigo {
    width: 180px;
    height: 35px;
}

.AtividadesLista #btnBusca {
    height: 35px;
}


.AtividadesLista #data_ini, .AtividadesLista #data_fim {
    width: 180px;
    height: 36px;
    display: block;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}